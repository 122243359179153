import React from "react"
import { Link } from "gatsby"
import Layout from "../templates/layout"
import SEO from "../components/seo/seo"
import Hero from "../components/hero/hero"
import Card from "../components/card/card"
import LinkCard from "../components/linkCard/linkCard"
import HaveQuestions from "../components/haveQuestions/haveQuestions"
import BrandLogo from "../images/logos/jevtana-logo.svg"
import QuestionsIcon from "../images/icons/icon-questions-jevtana.svg"
import AffordIcon from "../images/icons/icon-financial-jevtana.png"
import EnrollIcon from "../images/icons/icon-enroll-jevtana-hero.png"
import FAQIcon from "../images/icons/icon-faq-jevtana.png"
import BackToTop from "../components/backToTop/BackToTop";

const pageContent = () => (

    <Layout pageid="page-home-elitek">
      <SEO
      title="CareASSIST for patients prescribed JEVTANA® (cabazitaxel) | Patient Site"
      keywords="CareASSIST, Sanofi, JEVTANA® (cabazitaxel), Patient Site"
      description="Info for patients on CareASSIST by Sanofi, including financial assistance, enrollment, and resource support for JEVTANA patients. Contact a Care Manager. See Prescribing Info, including Boxed WARNING, and Patient Info"
      />
      <Hero
      headline={<>Helping eligible patients access <span className="jev-headline">JEVTANA</span></>}
      copy="CareASSIST guides you through your JEVTANA treatment journey, with a dedicated Care Manager to provide support every step of the way"
      hasButton={false}
      brandLogo={BrandLogo}
      brandAlt="Jevtana Logo"
      btnLink="/jevtana/enrollment/"
      btnText="Talk to your doctor about enrolling today"
      btnClass="jevtana"
      hasLogo={true}
      subheadline="Talk to your doctor about enrolling today"
      linkId="patient-jevtana-enrolling-today"
      />      
      <section className="content-section" id="overview">
        <div className="flex-section evenly home-card-container">
          {/* Vertical Icon Card Examples - no extra class needed */}
          <LinkCard
          img={AffordIcon}
          alt="Financial Assistance Icon"
          cardTitle={<>Financial<br/>assistance</>}
          cardText="Discover how much you may be able to save"
          cardLink=""
          cardClass="product-home jevtana-home"
          linkID="patient-jevtana-financial-assistance-link"
          linkImgID="financial-assistance-link-image"
          linkTitleID="financial-assistance-link-title"
          linkTxtID="financial-assistance-link-text"
          btnLink="/jevtana/financial-assistance"
          btnClass="jevtana"
          />
          <LinkCard
          img={EnrollIcon}
          alt="Resource support icon"
          cardTitle={<>Resource<br/>support</>}
          cardText="Get connected to independent support organizations and resources"
          cardLink=""
          cardClass="product-home jevtana-home"
          linkID="patient-jevtana-resource-support-link"
          linkImgID="enrollment-link-image"
          linkTitleID="enrollment-link-title"
          linkTxtID="enrollment-link-text"
          btnLink="/jevtana/resource-support"
          btnClass="jevtana"
          />
          <LinkCard
          img={FAQIcon}
          alt="Frequently Asked Questions Icon"
          cardTitle="Frequently asked questions"
          cardText="Your questions, answered"
          cardLink=""
          cardClass="product-home jevtana-home"
          linkID="patient-jevtana-faq-link"
          linkImgID="faq-link-image"
          linkTitleID="faq-link-title"
          linkTxtID="faq-link-text"
          btnLink="/jevtana/faqs"
          btnClass="jevtana"
          />
        </div>
        {/* <div className="title-bar">
          <h2>Useful resources</h2>
          <Link to="/jevtana/resource-support/" className="view-all" title="View all resources link">View all resources</Link>
        </div>
        <div className="flex-section between">
          <Card 
          cardTitle="CareASSIST patient overview flashcard"
          cardClass="jevtana"
          cardText="A brief brochure explaining our program"
          isExternalLink={true}
          linkUrl="../pdfs/careassist-patient-support-program-overview-resource-patient.pdf"
          linkClass="download-icon"
          linkTitle="Download the Patient overview brochure"
          
          />
          <Card 
          cardTitle="Enrollment form"
          cardClass="jevtana"
          cardText="Complete this form with your doctor to enroll in CareASSIST"
          isExternalLink={true}
          linkUrl="../pdfs/careassist-program-enrollment-form.pdf"
          linkClass="download-icon"
          linkTitle="Download the Enrollment form"
          
          />
          <Card 
          cardTitle="Spanish enrollment form"
          cardClass="jevtana"
          cardText="Spanish language enrollment form"
          isExternalLink={true}
          linkUrl="../pdfs/careassist-program-enrollment-form-spanish-version.pdf"
          linkClass="download-icon"
          linkTitle="View the Letter of medical necessity site"
          
          />
          
        </div> */}
        <p className="pi-paragraph">Please see full <a id="patient-jevtana-pi" href="https://products.sanofi.us/jevtana/jevtana.html" target="_blank" rel="noreferrer">Prescribing Information</a>, including Boxed WARNING, and{" "}
          <a
          id="patient-jevtana-patient-info"
            target="_blank"
            href="https://products.sanofi.us/Jevtana/Jevtana.html#section-21"
          >
            Patient Information
          </a>.
        </p>
        <HaveQuestions icon={QuestionsIcon}/>
      </section>
      <div className="back-to-top-container">
        <BackToTop/>
      </div>
    </Layout>

)

export default pageContent